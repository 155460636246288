.emotic-image {
    opacity: 0.3;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: scale(1);
}

.emotic-image:hover {
    opacity: 1;
    transform: scale(1.05);
}

.emotic-image:active {
    opacity: 0.7;
    transform: scale(0.95);
}

.emotic-image.active {
    opacity: 1;
}

.workstation-button {
    opacity: 0.7;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: scale(1);
    background: black;
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    color: white;
}

.workstation-button:hover {
    opacity: 1;
    transform: scale(1.05);
}

.workstation-button:active {
    opacity: 0.7;
    transform: scale(0.95);
}

.workstation-button.active {
    opacity: 1;
    background: white;
    border-width: 1px;
    border-style: solid;
    color: black;
}
