.icon-button {
    cursor: pointer;
    border-radius: 100px; /* Ensure units are specified */
    background-color: #BF04CD;
    width: 30px; /* Ensure units are specified */
    height: 30px; /* Ensure units are specified */
    align-items: center;
    display: flex;
    justify-content: center;
    transition: background-color 0.3s; /* Smooth transition for background color */
}